import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showLoader, showMessage, handleHTTPError } from 'store/actions';
import GeneralSetting from './GeneralSettings';
import CmtyEventSetting from './cmtysetting';
import ServiceFeeSettings from './ServiceFeeSettings';
import OrganizationTagSetting from './OrganizationTagSetting';
import AgeRanges from './AgeRanges';

class SystemManage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    render() {
        return (
            <>
                <GeneralSetting />
                <OrganizationTagSetting />
                <ServiceFeeSettings />
                <CmtyEventSetting />
                <AgeRanges />
            </>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { isLoader } = ui;
    const { authUser } = user;
    return { authUser, isLoader };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
    })(SystemManage)
);
