import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'components/Sidebar/index';
import Dashboard from './Dashboard/index';
import Clubs from './Clubs';
import CourtsReport from './CourtsReport';
import IssuesReport from './IssuesReport';
import Profile from './Profile/index';
import SystemManage from './SystemManage';
import CmtyEventUsers from './CmtyEventUsers';
import ClubInvoices from './ClubInvoices';
import UserInvoices from './UserInvoices';
import DBManage from './DBManage';
import RouteContentLayout from 'components/RouteContentLayout';
import Developers from './Developers';
import NonMembers from './NonMembers';
class RouteApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <Sidebar />
                <Header />
                <RouteContentLayout>
                    <Switch>
                        <Route path="/dashboard" component={Dashboard} />
                        <Route path="/profile" component={Profile} />
                        <Route path="/clubs" component={Clubs} />
                        <Route path="/courtsreport" component={CourtsReport} />
                        <Route path="/issuesreport" component={IssuesReport} />
                        <Route path="/dbmanage" component={DBManage} />
                        <Route path="/systemmanage" component={SystemManage} />
                        <Route path="/cmtyeventusers" component={CmtyEventUsers} />
                        <Route path="/non-members" component={NonMembers} />
                        <Route path="/club-invoices" component={ClubInvoices} />
                        <Route path="/user-invoices" component={UserInvoices} />
                        <Route path="/developers" component={Developers} />
                    </Switch>
                </RouteContentLayout>
            </>
        );
    }
}

const mapStateToProps = ({ user }) => {
    const { authUser } = user;

    return { authUser };
};

export default connect(mapStateToProps, {})(RouteApp);
