import axios from './axios';
import API_URL from './ApiUrl';
import store from 'store';

export const signIn = async (params) => {
    return await axios
        .post(`${API_URL.SIGNIN_URL}`, params)
        .then((data) => {
            store.set('token', data.data.token);
            return data.data;
        })
        .catch((error) => {
            throw error.response;
        });
};

// export const signUp = async(params) => {
//     return await axios.post(`${API_URL.SIGNUP_URL}`, params)
//         .then(data => {
//             if(data.data.code === 200) store.set('token', data.data.token)
//             return data.data
//         })
//         .catch(error => {throw error.response.dat});
// };

export const updateProfile = async (params) => {
    return await axios
        .post(`${API_URL.UPDATE_PROFILE_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const reportCourts = async (params) => {
    return await axios
        .post(`${API_URL.REPOrT_COURTS}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const forgotPassword = async (params) => {
    return await axios
        .post(`${API_URL.FORGOTPASS_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const changePassword = async (params) => {
    return await axios
        .post(`${API_URL.CHANGEPASS_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const resetPassword = async (params) => {
    return await axios
        .post(`${API_URL.RESETPASS_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const verifyCode = async () => {
    return await axios
        .post(`${API_URL.VERIFYCODE_URL}`, {})
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const verify = async (params) => {
    return await axios
        .post(`${API_URL.VERIFY_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const getDashboardData = async () => {
    return await axios
        .get(`${API_URL.GET_DASHBOARDDATA_URL}`)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const createClub = async (params) => {
    return await axios
        .post(`${API_URL.CREATE_CLUB_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const findClubsWithDetails = async (params) => {
    return await axios
        .get(`${API_URL.GET_CLUBS_URL}`, { params })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const retrieveClubs = async (params) => {
    return await axios
        .get(`${API_URL.SEARCH_CLUB_URL}`, { params })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const getClub = async (params) => {
    return await axios
        .get(`${API_URL.GET_CLUB_URL}`, { params })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const blockClub = async (params) => {
    return await axios
        .post(`${API_URL.BLOCK_CLUB_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const activeClub = async (params) => {
    return await axios
        .post(`${API_URL.ACTIVE_CLUB_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const acceptClubRsService = async (params) => {
    return await axios
        .post(`${API_URL.ACCEPT_CLUBRSSERVICE_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const rejectClubRsService = async (params) => {
    return await axios
        .post(`${API_URL.REJECT_CLUBRSSERVICE_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const controlClubFeature = async (params) => {
    return await axios
        .post(`${API_URL.CONTROL_CLUBFEATURE_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const updateClubProfile = async (params) => {
    return await axios
        .post(`${API_URL.UPDATE_CLUBPROFILE_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const updateClubSettingData = async (params) => {
    return await axios
        .post(`${API_URL.UPDATE_CLUBSETTINGS_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const changeClubPassword = async (params) => {
    return await axios
        .post(`${API_URL.RESET_CLUBPASS_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const searchReports = async () => {
    return await axios
        .get(`${API_URL.SEARCH_REPORTS_URL}`)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const getReport = async (params) => {
    return await axios
        .get(`${API_URL.GET_REPORT_URL}/${params._id}`)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const resolveReport = async (params) => {
    return await axios
        .post(`${API_URL.RESOLVE_REPORT_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const getSysManageData = async (params) => {
    return await axios
        .get(`${API_URL.GET_SYSMANAGE_URL}`, { params })
        .then((data) =>{ 
            return data.data;
        })
        .catch((error) => {
            throw error.response;
        });
};

export const updateSysManage = async (params) => {
    return await axios
        .post(`${API_URL.UPDATE_SYSMANAGE_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const getDBBackupFiles = async (params) => {
    return await axios
        .get(`${API_URL.GET_DBBACKUPFILES_URL}`, { params })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const restoreDatabase = async (params) => {
    return await axios
        .post(`${API_URL.RESTORE_DB_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const backupDatabase = async (params) => {
    return await axios
        .post(`${API_URL.BACKUP_DB_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const downloadBackupFile = async (params) => {
    return await axios
        .get(`${API_URL.DOWNLOAD_BACKUPFILE_URL}`, { responseType: 'blob', params: params })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const uploadBackupFile = async (params) => {
    const headers = {
        'content-type': 'multipart/form-data',
    };
    const formData = new FormData();
    formData.set('file', params.file);
    return await axios
        .post(`${API_URL.UPLOAD_BACKUPFILE_URL}`, formData, { headers })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

/**
 * Forum
 */

export const updatePost = async (params) => {
    return axios
        .post(`${API_URL.UPDATE_POST_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const searchPosts = async (params) => {
    return axios
        .get(`${API_URL.SEARCH_POST_URL}`, { params })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const getPost = async (params) => {
    return axios
        .get(`${API_URL.GET_POST_URL}/${params._id}`)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const deletePost = async (params) => {
    return axios
        .post(`${API_URL.DELETE_POST_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const createComment = async (params) => {
    return axios
        .post(`${API_URL.CREATE_COMMENT_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const updateComment = async (params) => {
    return axios
        .post(`${API_URL.UPDATE_COMMENT_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const publishComment = async (params) => {
    return axios
        .post(`${API_URL.PUBLISH_COMMENT_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const searchComments = async (params) => {
    return axios
        .get(`${API_URL.SEARCH_COMMENT_URL}`, { params })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const getComment = async (params) => {
    return axios
        .get(`${API_URL.GET_COMMENT_URL}/${params._id}`)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const deleteComment = async (params) => {
    return axios
        .post(`${API_URL.DELETE_COMMENT_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

/*** members */
export const retrieveMembers = async (params) => {
    return axios
        .get(`${API_URL.RETRIEVE_MEMBER_URL}`, { params })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const createMember = async (params) => {
    return await axios
        .post(`${API_URL.CREATE_MEMBER_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const readMember = async (params) => {
    return await axios
        .get(`${API_URL.READ_MEMBER_URL}/${params._id}`)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const updateMember = async (params) => {
    return await axios
        .post(`${API_URL.UPDATE_MEMBER_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

/** Invoices */
export const createInvoice = async (params) => {
    return await axios
        .post(`${API_URL.INVOICE_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const updateInvoice = async (params) => {
    return await axios
        .patch(`${API_URL.INVOICE_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const payInvoice = async (params) => {
    return await axios
        .post(`${API_URL.INVOICE_URL}/pay`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const retrieveInvoices = async (params) => {
    return await axios
        .get(`${API_URL.INVOICE_URL}`, { params })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const readInvoice = async (params) => {
    return await axios
        .get(`${API_URL.INVOICE_URL}/${params._id}`)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const deleteInvoice = async (params) => {
    return await axios
        .delete(`${API_URL.INVOICE_URL}`, { params })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

/** Cmty events */
export const retrieveCmtyEvents = async (params) => {
    return await axios
        .get(`${API_URL.CMTY_EVENT_URL}`, { params })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

/** Club Payment fee setting */
export const readClubPaymentFeeSetting = async (params) => {
    return await axios
        .get(`${API_URL.PAYMENT_FEESETTING_URL}`, { params })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};
export const updateClubPaymentFeeSetting = async (params) => {
    return await axios
        .post(`${API_URL.PAYMENT_FEESETTING_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const fileUpload = async (params) => {
    const formData = new FormData();
    formData.append('file', params.file);
    formData.append('type', params.type);
    return await axios
        .post(`${API_URL.UPLOAD_FILE_URL}`, formData)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const registerDeveloper = async (params) => {
    return await axios
        .post(`${API_URL.DEVELOPER_URL}`, params)
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const retrieveDevelopers = async (params) => {
    return await axios
        .get(`${API_URL.DEVELOPER_URL}`, { params })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};

export const deleteDeveloper = async (params) => {
    return await axios
        .delete(`${API_URL.DEVELOPER_URL}`, { params })
        .then((data) => data.data)
        .catch((error) => {
            throw error.response;
        });
};
