import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import Pagination from 'react-js-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showLoader, showMessage, setMemberSearchParams, handleHTTPError } from 'store/actions';
import TextBox from 'components/controls/TextBox';
import { retrieveMembers } from 'apis';
import { Constants } from 'utils/constants';
import PageContentLayout from 'components/PageContentLayout';
import AddMemberDlg from 'components/AddMemberDlg';
import SelectItem from 'components/controls/SelectItem';

class NonMemberList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalCount: 0,
            members: [],
            currentPage: 1,
            countPerPage: 10,
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            zipCode: '',
            status: 'all',
            showDlg: false,
        };
    }

    componentDidMount = () => {
        if (this.props.memberSearchParams) {
            this.setState(
                {
                    email: this.props.memberSearchParams.email || '',
                    firstName: this.props.memberSearchParams.firstName || '',
                    lastName: this.props.memberSearchParams.lastName || '',
                    phoneNumber: this.props.memberSearchParams.phoneNumber || '',
                    zipCode: this.props.memberSearchParams.zipCode || '',
                    status: this.props.memberSearchParams.status || 'all',
                },
                this.search
            );
        } else {
            this.search();
        }
    };

    changePaination = (page) => {
        this.setState(
            {
                currentPage: page,
            },
            () => this.search((page - 1) * this.state.countPerPage)
        );
    };

    search = (skip = 0) => {
        const params = {
            isNonMember: true,
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phoneNumber: this.state.phoneNumber,
            zipCode: this.state.zipCode,
            skip: skip || 0,
            limit: this.state.countPerPage,
        };
        if (this.state.status === 'active') params.isActivated = true;
        if (this.state.status === 'inactive') params.isActivated = false;

        this.props.showLoader(true);
        retrieveMembers(params)
            .then((data) => {
                this.props.showLoader(false);
                this.setState({
                    members: data.data,
                    totalCount: data.totalCount,
                    currentPage: params.skip / this.state.countPerPage + 1,
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    toggle = () => {
        this.setState({ showDlg: !this.state.showDlg });
    };

    render() {
        const generateBody = () => {
            return this.state.members.map((user, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{user.zipCode}</td>
                        <td>{user.firstName}</td>
                        <td>{user.lastName}</td>
                        <td>{user.email}</td>
                        <td>{user.phoneNumber}</td>
                        <td>{Object.keys(Constants.GENDER)[user.gender]}</td>
                        <td className={user.isActivated ? 'text-primary' : 'text-danger'}>
                            {user.isActivated ? 'ACTIVATED' : 'INACTIVATED'}
                        </td>
                        <td className="text-center">
                            <div
                                className="text-gray pointer view"
                                onClick={() => {
                                    this.props.setMemberSearchParams(this.state);
                                    this.props.history.push(`${this.props.match.url}/${user._id}`);
                                }}
                            >
                                <FontAwesomeIcon icon="search" />
                                &nbsp;&nbsp;View
                            </div>
                        </td>
                    </tr>
                );
            });
        };

        const statusOptions = () => {
            return [
                { id: 'all', name: 'All' },
                { id: 'active', name: 'Activated Members' },
                { id: 'inactive', name: 'InActivated Members' },
            ];
        };

        return (
            <PageContentLayout
                title={
                    <h1 className="font-weight-bold">
                        Non-Members <span className="h3">(Total: {this.state.totalCount})</span>
                    </h1>
                }
            >
                <div className="d-flex justify-content-end mb-3">
                    <Button
                        color="secondary"
                        className="btn btn-lg btn-secondary m-0"
                        style={{ width: '200px' }}
                        onClick={this.toggle}
                    >
                        <FontAwesomeIcon icon="user-plus" className="mr-2" />
                        <span className="ml-2">New Member</span>
                    </Button>
                </div>
                <div className="border p-4 mb-3" style={{ borderRadius: '5px' }}>
                    <h3 className=" font-weight-bold">Filter Options</h3>
                    <Row className=" flex-wrap align-items-end justify-content-between">
                        <TextBox
                            label="First Name"
                            id="firstName"
                            name="firstName"
                            type="text"
                            className="col-md-3 mb-3"
                            value={this.state.firstName}
                            placeholder="John"
                            onChange={(firstName, e) => {
                                this.setState({ firstName });
                            }}
                            validationOption={{
                                showMsg: false,
                                name: 'First Name',
                                check: false,
                                required: false,
                            }}
                        />
                        <TextBox
                            label="Last Name"
                            id="lastName"
                            name="lastName"
                            className="col-md-3 mb-3"
                            type="text"
                            value={this.state.lastName}
                            placeholder="Michael"
                            onChange={(lastName, e) => {
                                this.setState({ lastName });
                            }}
                            validationOption={{
                                showMsg: false,
                                name: 'Last Name',
                                check: false,
                                required: false,
                            }}
                        />
                        <TextBox
                            label="Email"
                            className="col-md-3 mb-3"
                            id="email"
                            name="email"
                            type="text"
                            value={this.state.email}
                            placeholder="example@mail.com"
                            onChange={(email, e) => {
                                this.setState({ email });
                            }}
                            validationOption={{
                                showMsg: false,
                                name: 'Email',
                                reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                regMsg: 'Invalid Email',
                                check: false,
                                required: false,
                            }}
                        />
                        <TextBox
                            label="Phone Number"
                            className="col-md-3 mb-3"
                            id="phoneNumber"
                            name="phoneNumber"
                            type="number"
                            value={this.state.phoneNumber}
                            placeholder="4159875236"
                            onChange={(phoneNumber, e) => {
                                this.setState({ phoneNumber });
                            }}
                            validationOption={{
                                showMsg: false,
                                numberType: 'int',
                                name: 'phoneNumber',
                                check: false,
                                required: false,
                            }}
                        />
                        <TextBox
                            label="Zip Code"
                            className="col-md-3 mb-3"
                            id="zipCode"
                            name="zipCode"
                            type="number"
                            value={this.state.zipCode}
                            placeholder="4159875236"
                            onChange={(zipCode, e) => {
                                this.setState({ zipCode });
                            }}
                            validationOption={{
                                showMsg: false,
                                numberType: 'int',
                                name: 'phoneNumber',
                                check: false,
                                required: false,
                            }}
                        />
                        <SelectItem
                            label="Status"
                            id="status"
                            className="col-md-3 mb-3"
                            size="sm"
                            value={this.state.status}
                            optionList={statusOptions()}
                            onChange={(status) => {
                                this.setState({ status: status.id });
                            }}
                        />
                        <Col className="col-md-3 mb-3"></Col>
                        <Col className="col-md-3 mb-3">
                            <Button
                                color="primary"
                                size="lg"
                                block
                                onClick={() => {
                                    this.search();
                                }}
                            >
                                <FontAwesomeIcon icon="search" className="mr-3" />
                                <span className="ml-2">Search</span>
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="row">
                    <div className="col-12">
                        <table className="table table-bordered">
                            <thead className="bg-primary text-white">
                                <tr>
                                    <th>No</th>
                                    <th>Zip Code</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Gender</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.totalCount === 0 && (
                                    <tr>
                                        <td colSpan="8" className="text-danger text-center">
                                            No Data
                                        </td>
                                    </tr>
                                )}
                                {generateBody()}
                            </tbody>
                        </table>
                        {this.state.totalCount > this.state.countPerPage && (
                            <div className="row">
                                <div className="col-sm-4 mt-3">
                                    {`${
                                        this.state.countPerPage * this.state.currentPage > this.state.totalCount
                                            ? this.state.totalCount
                                            : this.state.countPerPage * this.state.currentPage
                                    }/${this.state.totalCount}`}
                                </div>
                                <div className="col-sm-8">
                                    <div className="pagination-content">
                                        <Pagination
                                            activePage={this.state.currentPage}
                                            itemsCountPerPage={this.state.countPerPage}
                                            totalItemsCount={this.state.totalCount}
                                            pageRangeDisplayed={3}
                                            onChange={this.changePaination}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <AddMemberDlg isOpen={this.state.showDlg} toggle={this.toggle} callback={this.search} />
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { isLoader } = ui;
    const { authUser, memberSearchParams } = user;
    return { isLoader, authUser, memberSearchParams };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        setMemberSearchParams,
        handleHTTPError,
    })(NonMemberList)
);
