import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import CmtyEventUserList from './list';
import CmtyEventUserDetails from './details';

class NonMembers extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={`${this.props.match.url}`} component={CmtyEventUserList} />
                <Route exact path={`${this.props.match.url}/:id`} component={CmtyEventUserDetails} />
            </Switch>
        );
    }
}

export default NonMembers;
